import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { GraphQLModule } from './graphql.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FirstmileEnrollComponent } from './firstmile-enroll/firstmile-enroll.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import { FirstMileComponent } from './firstmile/firstmile.component';
import { ReusableComponentsModule } from './modules/common/reusable-components/reusable-components.module';

@NgModule({
  declarations: [
    AppComponent,
    FirstmileEnrollComponent,
    OtpInputComponent,
    FirstMileComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GraphQLModule,
    ReactiveFormsModule,
    ReusableComponentsModule
    
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
