/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment'
import { enableProdMode } from '@angular/core';


if(environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref=>{
  console.log("Bootstrapped");
  console.log("App module with env:"+environment.graphqlUrl);
})
  .catch(err => console.error("Error bootstrapping angular", err));
